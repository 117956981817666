import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 100px;
`

const SectionBody = styled.p`
  margin: 5rem 1.075rem;
  text-align: center;
`

export default function Intro() {
  return (
    <Container id="latest-projects">
      <SectionBody>
        Here are some things I'm working on right now...
      </SectionBody>
    </Container>
  )
}
