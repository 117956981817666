import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Landing from '../components/Landing'
import LatestProjects from '../components/LatestProjects'
import Intro from '../components/Intro'
import Contact from '../components/Contact'
import Nav from 'components/Nav'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../theme'

const Title = styled.h1`
  padding-left: 20px;
  margin: 0 auto;
  max-width: 960px;
`

const NameTag = styled.span`
  font-size: 0.56em;
  color: #fff;
  background: #3b3b3b;
  position: absolute;
  left: 0;
  width: 100%;
  padding-left: 22px;
`

export const query = graphql`
  query {
    allSanityProject(sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          title
          caption
          description
          id
          coverImage {
            alt
            asset {
              url
              gatsbyImageData(fit: FILLMAX)
            }
          }
          sourceLink
          demoLink
          stack {
            link
            title
          }
          slug {
            current
          }
          _updatedAt
          _createdAt
        }
      }
      totalCount
    }
    sanityPage(slug: { current: { eq: "index" } }) {
      slug {
        current
      }
      intro
      title
      hero {
        backgroundImage {
          asset {
            url
            gatsbyImageData(fit: FILLMAX)
          }
        }
        caption
        title
      }
    }
  }
`

const IndexPage = ({ data }) => {
  console.log('Index data:', data)

  const projects = data.allSanityProject.edges
    .map(edge => edge.node)
    .slice(0, 3)
  const page = data.sanityPage

  console.log('Index projects:', projects)

  console.log('Index page:', page)

  return (
    <ThemeProvider theme={theme}>
      <Landing
        title={
          <Title>
            Design & <br />
            Development
            <br />
            <NameTag>by Christopher Clemons</NameTag>
          </Title>
        }
        // intro={
        //   'Hello! My name is Christopher Clemons.\nI use web technologies to build meaningful interactions between humans and computers.'
        // }
        intro={page.hero.caption}
        background={page.hero.backgroundImage?.asset.url}
        backgroundImage={page.hero.backgroundImage?.asset.gatsbyImageData}
        grayscale={100}
      />
      <Nav />
      <Layout>
        <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Intro />
        <LatestProjects projects={projects} />
        <Contact />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
