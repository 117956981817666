import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useMedia } from 'use-media'
import { Link } from 'gatsby'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { useTheme } from '@material-ui/core/styles'

const Container = styled.div`
  width: 100%;
  background: #fff;
  margin-top: 20px;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

const NavList = styled.ul`
  background: #fff;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;

  @media (max-width: 600px) {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
`

const NavItem = styled.li`
  background: #fff;
  // padding: 20px;
  margin: 0;
  height: 100%;
  transition: all 0.4s;

  :hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    height: 50px;
  }
`

const NavLink = styled(Link)`
  display: flex;
  padding: 20px;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &:visited {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
const Label = styled.p`
  @media (max-width: 600px) {
    width: 100%;
  }
`

export default function Nav(props) {
  const theme = useTheme()

  const isMobile = useMedia({ maxWidth: 600 })

  const [showNav, setShowNav] = useState(false)
  const [sticky, setSticky] = useState(false)

  let navRef = useRef(null)

  // TODO: Refactor "links" to StaticQuery
  // TODO: Get site page data with StaticQuery
  // and populate Navlist programmatically with data
  const links = [
    { title: 'Projects', href: '/projects' },
    { title: 'About', href: '/about' },
    { title: 'Contact', href: '/contact' },
  ]

  /** Sticky solution
   * https://pantaley.com/blog/Create-sticky-header-only-with-React-Hooks/
   * https://developers.google.com/web/updates/2012/08/Stick-your-landings-position-sticky-lands-in-WebKit
   */
  const handleScroll = e => {
    if (navRef.current) {
      setSticky(navRef.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <Container id="nav-container" ref={navRef}>
      <div
        style={{
          position: sticky ? 'fixed' : 'inherit',
          top: 0,
          width: '100%',
          zIndex: 1,
          backgroundColor: '#fff',
          textAlign: 'center',
        }}
      >
        {isMobile && (
          <div style={{ paddingTop: 5 }}>
            <IconButton onClick={() => setShowNav(!showNav)}>
              <MenuIcon />
            </IconButton>
          </div>
        )}
        <NavList sticky={sticky}>
          {!isMobile ? (
            links.map((link, i) => (
              <NavItem key={i}>
                <NavLink theme={theme} to={link.href}>
                  <Label theme={theme}>{link.title}</Label>
                </NavLink>
              </NavItem>
            ))
          ) : (
            <>
              <Drawer
                anchor="top"
                open={showNav}
                onClose={() => setShowNav(false)}
              >
                <NavList>
                  {links.map((link, i) => (
                    <NavItem key={i}>
                      <NavLink theme={theme} to={link.href}>
                        <Label theme={theme}>{link.title}</Label>
                      </NavLink>
                    </NavItem>
                  ))}
                </NavList>
              </Drawer>
            </>
          )}
        </NavList>
      </div>
    </Container>
  )
}
